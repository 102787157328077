import { useFlags } from 'launchdarkly-react-client-sdk';

// BEFORE CREATING A FLAG, READ FOR BEST PRACTICES:
// https://www.flagship.io/feature-flags-naming-conventions/
// https://docs.launchdarkly.com/guides/best-practices/creating-flags

// Flag naming convention:
// feature_{release/op/experiment/permission}_team_month_year{_temp, if temp}

// Do not use dots in the name, while allowed in LD,
// you cannot access flag with dots in the name in react/js
// We truned off auto camel casing on the provider, more here
// https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys

// Launch Darkly Up Time - Plan your default and failure states well:
// We cannot expect 100% uptime from launch darkly. Plan default values and error handling in implementation
// carefully to plan for cases when we may not be able to reach launch darkly to retrieve the flag value.

// WARNING: Launch Darkly will not have 100% uptime. We cannot guarantee a flag value is returned every time
// or that we will not receive the default flag value. Plan you default and failure states for flags and
// implementation very carefully. Think about, what happens if this flag value returns null, or the default
// specified? How will users be impacted? Manage and design for those cases.

// Type of flags:
// RELEASE: a flag used to roll out a release (enable or disable), will be temporary
// OP: operations flag used to turn on and off operations tooling, may or may not be temporary
// EXPERIMENT: A/B and other testing flags, will likely be temporary
// PERMISSION: flag to control feature permissions, eg Only enterprise users have feature XYZ, will not be temporary

// Usage eg:
// import { useFeatureFlag, FLAGS } from 'utils/launchdarkly/flags'
// const isTestFlagEnabled = useFeatureFlag(FLAGS.TEST);
export function useFeatureFlag(flag) {
  try {
    const flagValue = useFlags()[flag.name];
    return typeof flagValue === 'undefined' ? flag.default : flagValue;
  } catch (err) {
    console.error('error from Launch Darkly', err);
    return flag?.default || false;
  }
}

// For temporary flags, like release flags, it is considered best practice to post a branch with
// the flag removed so that flag cleanup is as easy as posssible.

export const FLAGS = {
  // TEMPLATE: {
  //  name        : 'feature_{release/op/experiment/permission}_team_month_year{_temp, if temp}',
  //  description : 'Feature flag to allow enterprise customers access to enterprise feautres',
  //  default     : false,
  //  values      : [true, false],
  //  useFlag     : () => useFlags().<TEMPLATE.name>,
  // },
  // TEST: {
  //  name        : 'test_mxo_12_21_temp',
  //  description : 'Feature flag to test flagging wrapper',
  //  default     : false,
  //  values      : [true, false],
  //  useFlag     : () => useFlags().test_mxo_12_21_temp,
  // },
  CUSTOMER_NOTES: {
    name: 'migration_add_customer_notes_platform_10_11_temp',
    description: 'Temporary flag to show/hide customer administration notes',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().migration_add_customer_notes_platform_10_11_temp,
  },
  HIDE_COHORT_NOTES: {
    name: 'feature_hide_cohort_notes_platform_10_31_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_hide_cohort_notes_platform_10_31_temp,
  },
  MEMBER_MERGE: {
    name: 'feature_member_merge_admin_release_cx_5_23_temp',
    description: 'Release feature flag to allow admins to merge members',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().feature_member_merge_admin_release_cx_5_23_temp,
  },
  TRIALS: {
    name: 'trials_release_cx_09_22_temp',
    description: 'Temporary flag to show/hide the trials on boarding/off boarding flow.',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().trials_release_cx_09_22_temp,
  },
  ADMIN_PORTAL_COMMS_RELEASE: {
    name: 'admin_portal_channel_comms_release_nbd_02_07_temp',
    description: 'release flag for admin portal release flag',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().admin_portal_channel_comms_release_nbd_02_07_temp,
  },
  CANCEL_APPOINTMENT: {
    name: 'appointment_unhappy_path_12_28_temp',
    description: 'Temporary flag to enable/disable the cancel appointment modal.',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().appointment_unhappy_path_12_28_temp,
  },
  MAINTENANCE_MODE: {
    name: 'maintenance_mode_release_pfrm_03_2023_temp',
    description: 'Manually control maintenance polling',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().maintenance_mode_release_pfrm_03_2023_temp,
  },
  TABLE_REDESIGN_ALL: {
    name: 'feature_table_redesign_all_platform_07_23_temp',
    description: 'table redesign',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().feature_table_redesign_all_platform_07_23_temp,
  },
  BOA_PILOT_PROGRAM: {
    name: 'boa_pilot_mxo_08_2023',
    description: 'Bank of America pilot to create covered lives without a census',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().boa_pilot_mxo_08_2023,
  },
  TABLE_REDESIGN_ASSESSMENTS: {
    name: 'feature_table_redesign_assessments_platform_01_24_temp',
    description: 'table redesign - assessments',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_table_redesign_assessments_platform_01_24_temp,
  },
  RECS_M2: {
    name: 'admin_recommendations_m2_mxa_01_24_temp',
    description: 'Use new recs m1 algorithm to generate therapist recommendations',
    default: false,
    values: ['true', 'false'],
    useFlag: () => useFlags().admin_recommendations_m2_mxa_01_24_temp,
  },
  COHORT_MD_EDITOR: {
    name: 'feature_cohort_md_editor_platform_10_23_temp',
    description: 'New MD editor on cohort administration notes',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_cohort_md_editor_platform_10_23_temp,
  },
  NEW_BROWSE_THERAPISTS: {
    name: 'release_new_browse_therapists_pfrm_2024-02-09',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_new_browse_therapists_pfrm_2024-02-09'],
  },
  ALLOW_CRISIS_SUPPORT_NUMBER: {
    name: 'global_one_release_lob_3_4_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['global_one_release_lob_3_4_2024_temp'],
  },
  FILTER_BY_PROVIDER_TAGS: {
    name: 'release_browse_by_provider_tags_pfrm_2024_05_22_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_browse_by_provider_tags_pfrm_2024_05_22_temp'],
  },
  RELEASE_NEW_ASSIGN_PROVIDER_MODAL: {
    name: 'release_new_assign_provider_modal_pfrm_2024_06_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_new_assign_provider_modal_pfrm_2024_06_temp'],
  },
  SHOW_CONNECTED_BENEFITS: {
    name: 'connected_benefits_mxb_07_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().connected_benefits_mxb_07_24_temp,
  },
  SHOW_SDOH_ASSESSMENT_PERMISSION: {
    name: 'show_sdoh_assessment_permission_mxb_07_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['show_sdoh_assessment_permission_mxb_07_24_temp'],
  },
  DEACTIVATIONS: {
    name: 'feature_deactivation_platform_07_24',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_deactivation_platform_07_24,
  },
  CANCEL_UNCONFIRMED_APPOINTMENTS_JOB: {
    name: 'release_cancel_non_confirmed_appointments_job_mfopx_07_31_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().release_cancel_non_confirmed_appointments_job_mfopx_07_31_temp,
  },
  PROVIDER_HOLD_STATUSES: {
    name: 'care_provider_general_and_quality_hold',
    description: 'Feature flag for new hold statuses for care providers (general and quality)',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().care_provider_general_and_quality_hold,
  },
  SHOW_COMMUNITY_CARE_SOLUTION: {
    name: 'feature_community_care_solution_platform_08_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_community_care_solution_platform_08_24_temp,
  },
  SHOW_SPECIALTY_CARE_NAVIGATOR: {
    name: 'speacialty_care_navigator_release_admin_sudbhb_53_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().speacialty_care_navigator_release_admin_sudbhb_53_2024_temp,
  },
  SHOW_PEER_RECOVERY: {
    name: 'peer_recovery_release_admin_sudbhb_07_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().peer_recovery_release_admin_sudbhb_07_2024_temp,
  },
  SHOW_GATED_BOOKING_TOGGLE: {
    name: 'gated_booking_toggle_release_mfopx_09_23_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().gated_booking_toggle_release_mfopx_09_23_temp,
  },
  BAMBOO_SHOW_ADMIN_CONTROLS: {
    name: 'bamboo_show_admin_controls',
    description: 'Enable all new controls for Bamboo Admin',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().bamboo_show_admin_controls,
  },
  NEW_PROVIDER_FORM: {
    name: 'release_new_provider_form_pfrm_2024_08_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().release_new_provider_form_pfrm_2024_08_temp,
  },
  APPOINTMENT_PAGE_REDESIGN: {
    name: 'feature_redesign_appointment_details_platform_09_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_redesign_appointment_details_platform_09_24_temp,
  },
  BULK_CLONE_PLAN_YEARS: {
    name: 'plan_year_bulk_cloning_core_2024_09_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().plan_year_bulk_cloning_core_2024_09_temp,
  },
  PERMISSIONS_DEACTIVATION_TOGGLE: {
    name: 'permissions_auto_deactivations_toggle_platform_10_2024',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().permissions_auto_deactivations_toggle_platform_10_2024,
  },
  CUSTOMER_CANCELLATION_POLICY_COPY: {
    name: 'feature_customer_cancellation_policy_10_09_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_customer_cancellation_policy_10_09_temp,
  },
  SHOW_VALIDATED_INSURANCE_POLICY: {
    name: 'feature_show_validated_insurance_policy_prcm_10_14',
    description: 'show validated insurance policy for member eligible for insurance validation',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_show_validated_insurance_policy_prcm_10_14,
  },
  CANCELLATION_REASONS_V2: {
    name: 'admin_cancellation_modal_reasons_11__18_2024',
    description: 'Enables the new cancellation reasons on the cancel modal in the calendar',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().admin_cancellation_modal_reasons_11__18_2024,
  },
  SHOW_SPECIALTY_CARE_PLAN_YEAR_CHECKBOX: {
    name: 'show_specialty_care_plan_year_checkbox_mxsc_11_24',
    description: 'Shows/Hides the new specialty care checkbox',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().show_specialty_care_plan_year_checkbox_mxsc_11_24,
  },
  MEMBER_COMMUNICATION_DATE: {
    name: 'feature_member_communications_11_26_2024_temp',
    description: 'Member communications date picker toggle on customer',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_member_communications_11_26_2024_temp,
  },
  RELEASE_NEW_MEMBER_MOVER: {
    name: 'release_new_member_move_tool_pfrm_2024-12',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_new_member_move_tool_pfrm_2024-12'],
  },
  LINK_PRIMARY_COVERED_LIFE: {
    name: 'feature_link_primary_covered_life_modal_12_24',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['feature_link_primary_covered_life_modal_12_24'],
  },
  SESSION_ALLOCATIONS_REWRITE: {
    name: 'feature_revamp_appointment_allocations_01_25_platform_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_revamp_appointment_allocations_01_25_platform_temp,
  },
  CHANNEL_PARTNER_UPDATE: {
    name: 'feature_channel_partner_ui_redesign_02_25_platform_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_channel_partner_ui_redesign_02_25_platform_temp,
  },
  INSURANCE_SIGN_UP: {
    name: 'insurance_sign_up_rcm_03_25_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().insurance_sign_up_rcm_03_25_temp,
  },
  CUSTOMER_RATE_CARDS: {
    name: 'show_customer_rate_cards_rcm_03_2025_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().show_customer_rate_cards_rcm_03_2025_temp,
    // Removal task: https://springhealth.atlassian.net/browse/RCM-1788
  },
};
