import Auth from '../auth';
import CookieUtils from '../../utils/cookies';

export const fetchHandler = async (url, options, numberOfRefetchAttempts = 0) => {
  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (!response.ok) {
      throw response;
    }
    return { response, data };
  } catch (e) {
    if (e?.status === 403 && numberOfRefetchAttempts === 0) {
      try {
        await Auth.refreshAccessToken();
      } catch (e) {
        throw e;
      }
      return fetchHandler(
        url,
        {
          ...options,
          headers: {
            ...options.headers,
            ...CookieUtils.getAuthorizationHeader(),
          },
        },
        numberOfRefetchAttempts + 1
      );
    } else {
      throw e;
    }
  }
};

export default fetchHandler;
