/**
 * Enums for care navigator contracts.
 */

export const CareNavigationContractType = Object.freeze({
  Regular: 'regular',
  Blended: 'blended',
  Dedicated: 'dedicated',
  None: 'no_contract',
});
