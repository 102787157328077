import LocalStorageUtils from '../utils/localStorage';
import { ddGlobalSessionId } from '../utils/telemetry';
import { API_BASE, AUTH_API_DOMAIN } from 'constants/api';
import CookieUtils from '../utils/cookies';

export const SH_MAGIC_ID_HEADER_KEY = 'x-springhealth-magic-id';

export const generateRequestHeaders = (useCookies = true) => ({
  ...(useCookies ? CookieUtils.getAuthorizationHeader() : LocalStorageUtils.getAuthorizationHeader()),
  [SH_MAGIC_ID_HEADER_KEY]: ddGlobalSessionId.getSessionId(),
  'Content-Type': 'application/json',
});

const runReportingIntegrationJob = async (integration_id: number, body) => {
  return fetch(`${API_BASE}/reporting_integration_ad_hoc_runs/${integration_id}`, {
    method: 'POST',
    headers: generateRequestHeaders(),
    body: JSON.stringify(body),
  });
};

const submitCensus = async (body) => {
  const response = await fetch(`${API_BASE}/census_ingestions`, {
    method: 'POST',
    headers: LocalStorageUtils.getAuthorizationHeader(),
    body,
  });

  return response.json();
};

const refreshToken = async () => {
  return fetch(`${AUTH_API_DOMAIN}/oauth/access_token`, {
    method: 'POST',
    headers: {
      ...CookieUtils.getAuthorizationHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: CookieUtils.get('refresh_token'),
      grant_type: 'refresh_token',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
    }),
  });
};

export default {
  generateRequestHeaders,
  runReportingIntegrationJob,
  submitCensus,
  refreshToken,
};
