import { ADMIN, SUPER_ADMIN } from 'constants/roles';

const LocalStorageUtils = {
  getUserScopes: () => window?.localStorage.getItem('scopes')?.split(',') || [],

  // Saves the needed headers for api requests
  saveHeaders: (headers, uid) => {
    const { access_token, refresh_token, scopes, token_type } = headers;

    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('scopes', scopes);
    localStorage.setItem('token_type', token_type);
    localStorage.setItem('uid', uid);
  },

  updateHeaders: (headers) => {
    for (const header in headers) {
      localStorage.setItem(header, headers[header]);
    }
  },

  updateTokenExpiration(access) {
    localStorage.setItem('access_token_expires_at', access);
  },

  // Clears all headers from local storage
  clearHeaders: () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('token_type');
    localStorage.removeItem('scopes');
    localStorage.removeItem('uid');
    localStorage.removeItem('uuid');
  },

  getAuthorizationHeader: () => {
    const access_token = localStorage.getItem('access_token');
    const token_type = localStorage.getItem('token_type');

    return { Authorization: `${token_type} ${access_token}` };
  },

  isAdmin: () => LocalStorageUtils.getUserScopes().includes(ADMIN),

  isSuperAdmin: () => LocalStorageUtils.getUserScopes().includes(SUPER_ADMIN),
};

export default LocalStorageUtils;
