import { ApolloClient, InMemoryCache, ApolloLink, from, HttpLink, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';

import { fieldPolicies } from './typePolicies/fieldPolicies';
import QueryTypePolicies from './typePolicies/query';
import { API_BASE, API_GRAPHQL, ATLAS_API_DOMAIN } from 'constants/api';
import { ddGlobalSessionId } from 'utils/telemetry';

import type { NormalizedCacheObject } from '@apollo/client';
import Api from '../../services/api';
import CookieUtils from '../cookies';
import LocalStorageUtils from '../localStorage';

interface GlobalFetch extends NodeJS.Global {
  fetch: any;
}

const isBrowserEnv = typeof window !== 'undefined';

// Polyfill fetch() on the server (used by apollo-client)
if (!isBrowserEnv) {
  (global as GlobalFetch).fetch = fetch;
}

let apolloClient: null | ApolloClient<NormalizedCacheObject> = null;

const authMiddleware = new ApolloLink((operation, forward) => {
  if (localStorage && typeof window !== 'undefined') {
    const access_token = CookieUtils.get('access_token');
    const token_type = CookieUtils.get('token_type');

    operation.setContext({
      headers: {
        Authorization: `${token_type} ${access_token}`,
        'x-springhealth-client-path': window.location.pathname,
        'x-springhealth-magic-id': ddGlobalSessionId.getSessionId(),
        'x-springhealth-client-type': 'Spring Health Admin Portal',
      },
    });
  }

  return forward(operation);
});

let refreshTokenPromiseInFlight = false;
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && graphQLErrors.map((err) => `${err}`).includes('Access denied.')) {
    if (!refreshTokenPromiseInFlight) {
      refreshTokenPromiseInFlight = true;
    }

    return fromPromise(
      Api.refreshToken()
        .then((res) => res.json())
        .then((data) => {
          const { token_type, access_token } = data;
          CookieUtils.updateHeaders(data);
          LocalStorageUtils.updateHeaders(data);
          refreshTokenPromiseInFlight = false;
          // Update the operation's headers with the new token
          operation.setContext(({ headers }) => ({
            headers: {
              ...headers,
              Authorization: `${token_type} ${access_token}`,
            },
          }));
        })
        .catch((err) => {
          console.error('Token refresh failed:', err);
          window.location.replace('/logout');
          return null;
        })
    ).flatMap(() => forward(operation));
  }
  if (!graphQLErrors && !networkError) {
    return forward(operation);
  }
});

const clientLinkFetchOptions = {
  credentials: 'same-origin',
};

const rotomClientLink = new HttpLink({
  uri: `${API_BASE}${API_GRAPHQL}`,
  fetchOptions: {
    ...clientLinkFetchOptions,
  },
});

const atlasClientLink = new HttpLink({
  uri: `${ATLAS_API_DOMAIN}${API_GRAPHQL}`,
  fetchOptions: {
    ...clientLinkFetchOptions,
  },
});

const http = () =>
  // FYI, with the split method, if the boolean resolves to true, the first link is used, otherwise the second
  ApolloLink.split((operation) => operation.getContext().clientName === 'atlas', atlasClientLink, rotomClientLink);

function create() {
  return new ApolloClient({
    /**
     * NOTE - Do not set connectToDevTools to false.
     *
     * See https://springhealth.atlassian.net/wiki/x/uADdX
     * See https://springhealth.atlassian.net/browse/CORE-2126
     */
    connectToDevTools: true,
    ssrMode: !isBrowserEnv, // Disables forceFetch on the server (so queries are only run once)
    link: from([authMiddleware, errorLink, http()]),
    cache: new InMemoryCache({
      typePolicies: {
        ...QueryTypePolicies,
        ...fieldPolicies,
      },
    }),
  });
}

export default function initApollo() {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowserEnv) {
    return create();
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create();
  }

  return apolloClient;
}
