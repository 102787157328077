import { Box, Flex } from '@springcare/sh-component-library';
import { useEffect } from 'react';
import Auth from 'services/auth';
import { useRouter } from 'next/router';

export const PublicLayout = ({ children }) => {
  const router = useRouter();
  useEffect(() => {
    // If the user already has a valid cookie then we reroute them to the admin home page
    Auth.validateAccess().then((hasAccess) => {
      if (hasAccess && router.asPath !== '/') router.replace('/admin');
    });
  }, []);
  return (
    <Flex
      width="100%"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor="background-subtle"
    >
      <Box p={4} m={4}>
        {children}
      </Box>
    </Flex>
  );
};
