import Cookies from 'universal-cookie';

const buildCookies = () => {
  /**
   * Why is "staging" not included?
   *
   * The AWS dev, staging, and ephemeral envs all use the "staging" env variable. The problem is that the ephemeral URL is
   * structured differently than the other two, so the cookie domain would be set incorrectly, but only in that environment.
   */
  const IS_PRODUCTION_ENV = ['production', 'internal', 'demo'].includes(`${process.env.APP_ENV}`);

  const domain = IS_PRODUCTION_ENV ? `.${window.location.host.split('.').slice(-2).join('.')}` : undefined;

  return new Cookies(null, {
    path: '/',
    domain,
  });
};

export const CookieUtils = {
  saveHeaders: (headers, uid) => {
    const { access_token, refresh_token, scopes, token_type } = headers;
    const cookies = buildCookies();
    cookies.set('access_token', access_token);
    cookies.set('refresh_token', refresh_token);
    cookies.set('admin_to_compass_redirect_refactor', true);
    cookies.set('scopes', scopes);
    cookies.set('token_type', token_type);
    cookies.set('uid', uid);
  },

  get: (key) => {
    const cookies = buildCookies();
    return cookies.get(key);
  },

  getAuthorizationHeader: () => {
    const cookies = buildCookies();
    const access_token = cookies.get('access_token');
    const token_type = cookies.get('token_type');

    return { Authorization: `${token_type} ${access_token}` };
  },

  updateTokenExpiration(access) {
    const cookies = buildCookies();
    cookies.set('access_token_expires_at', access);
  },

  updateHeaders: (headers) => {
    const cookies = buildCookies();
    for (const header in headers) {
      cookies.set(header, headers[header]);
    }
  },

  clearHeaders: () => {
    const cookies = buildCookies();
    cookies.remove('access_token');
    cookies.remove('refresh_token');
    cookies.remove('access_token_expires_at');
    cookies.remove('admin_to_compass_redirect_refactor');
    cookies.remove('token_type');
    cookies.remove('scopes');
    cookies.remove('uid');
    cookies.remove('uuid');
  },
};

export default CookieUtils;
