import { datadogRum } from '@datadog/browser-rum';
import { ddGlobalSessionId } from 'utils/telemetry';

const REQUIRED_ENVS = [process.env.DATADOG_ID, process.env.DATADOG_TOKEN, process.env.DATADOG_ENV];

function hasAllDatadogEnvs() {
  return REQUIRED_ENVS.every((env) => !!env);
}

function ddStartView(router) {
  const regex = new RegExp(/admin\/compass/i);

  // Track non-Compass views only, and remove all UUIDs from paths
  if (router?.asPath && !regex.test(router.asPath)) {
    const normalizedPath = router.asPath.replace(/[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-z]{12}/i, '?');
    datadogRum.startView({ name: normalizedPath });
  }
}

export function initializeAndStartDD(router) {
  if (hasAllDatadogEnvs()) {
    datadogRum.init({
      allowedTracingUrls: [
        /https:\/\/.*\.springhealth\.com/,
        /https:\/\/.*\.springhealth\.cloud/,
        /https:\/\/.*\.springtest\.us/,
        /https:\/\/.*\.springhealth\.us/,
        /https:\/\/.*\.on-aptible.\.com/,
      ],
      applicationId: process.env.DATADOG_ID,
      clientToken: process.env.DATADOG_TOKEN,
      defaultPrivacyLevel: 'mask',
      env: process.env.DATADOG_ENV,
      sessionReplaySampleRate: 0,
      sessionSampleRate: 100,
      service: 'admin-portal',
      silentMultipleInit: true,
      site: 'datadoghq.com',
      traceSampleRate: 100,
      trackLongTasks: true,
      trackResources: true,
      trackViewsManually: true,
      version: process.env.DATADOG_RUM_VERSION,
    });

    datadogRum.setGlobalContextProperty('sh_magic_id', ddGlobalSessionId.getSessionId());

    ddStartView(router);
  }
}

export function datadogIdentify(userIdentifiers) {
  const { id } = userIdentifiers?.user || {};

  if (id) {
    datadogRum.setUser({ id });
  }
}
