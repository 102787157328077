const routes = {
  Home: {
    to: '/apps/Admin/_home',
    as: '/admin',
  },
  AssessmentList: {
    to: '/apps/Admin/assessments',
    as: '/admin/assessments',
  },
  AssessmentDetails: {
    to: '/apps/Admin/assessments/[id]',
    as: '/admin/assessments/:id',
  },
  AppTaskList: {
    to: '/apps/Admin/app_tasks',
    as: '/admin/app_tasks',
  },
  AppointmentList: {
    to: '/apps/Admin/appointments',
    as: '/admin/appointments',
  },
  AppointmentDetails: {
    to: '/apps/Admin/appointments/details/[id]',
    as: '/admin/appointments/details/:id',
    href: '/admin/appointments',
  },
  AppointmentDetailsV2: {
    to: '/apps/Admin/appointments/[id]',
    as: '/admin/appointments/:id',
    href: '/admin/appointments',
  },
  CensusIngestionCreate: {
    to: '/apps/Admin/census_ingestions/create',
    as: '/admin/census_ingestions/create',
  },
  CensusIngestionDetails: {
    to: '/apps/Admin/census_ingestions/[id]',
    as: '/admin/census_ingestions/:id',
  },
  CensusIngestionList: {
    to: '/apps/Admin/census_ingestions',
    as: '/admin/census_ingestions',
  },
  ChannelPartnerList: {
    to: '/apps/Admin/channel_partners',
    as: '/admin/channel_partners',
  },
  ChannelPartnerCreate: {
    to: '/apps/Admin/create/channel_partners',
    as: '/admin/create/channel_partners',
  },
  ChannelPartnerDetails: {
    to: '/apps/Admin/channel_partners/[id]',
    as: '/admin/channel_partners/:id',
  },
  DeactivationsList: {
    to: '/apps/Admin/deactivations',
    as: '/admin/deactivations',
  },
  DeactivationDetail: {
    to: '/apps/Admin/deactivations/[id]',
    as: '/admin/deactivations/:id',
  },
  DemoAccountList: {
    to: '/apps/Admin/demo_accounts',
    as: '/admin/demo_accounts',
  },
  DemoAccountCreate: {
    to: '/apps/Admin/demo_accounts/create',
    as: '/admin/demo_accounts/create',
  },
  ConnectedBenefitList: {
    to: '/apps/Admin/connected_benefits',
    as: '/admin/connected_benefits',
  },
  CreateAdmin: {
    to: '/apps/Admin/create/admin',
    as: '/admin/create/admin',
  },
  CreateData: {
    to: '/apps/Admin/create/test_data',
    as: '/admin/create/test_data',
  },
  CustomerList: {
    to: '/apps/Admin/customers',
    as: '/admin/customers',
  },
  CustomerDetails: {
    to: '/apps/Admin/customers/[id]',
    as: '/admin/customers/:id',
  },
  CustomerCreate: {
    to: '/apps/Admin/create/customers',
    as: '/admin/create/customers',
  },
  UserList: {
    to: '/apps/Admin/users',
    as: '/admin/users',
  },
  UserDetails: {
    to: '/apps/Admin/users/[id]',
    as: '/admin/users/:id',
  },
  AdminCreate: {
    to: '/apps/Admin/create/admin',
    as: '/admin/create/admin',
  },
  MemberList: {
    to: '/apps/Admin/members',
    as: '/admin/members',
  },
  CoveredLives: {
    to: '/apps/Admin/covered_lives',
    as: '/admin/covered_lives',
  },
  MemberDetails: {
    to: '/apps/Admin/members/[id]',
    as: '/admin/members/:id',
  },
  MemberCoveredLivesLookup: {
    to: '/apps/Admin/members/[id]/covered_lives_lookup',
    as: '/admin/members/:id/covered_lives_lookup',
  },
  MemberRecommendedTherapists: {
    to: '/apps/Admin/members/[id]/recommended_therapists',
    as: '/admin/members/:id/recommended_therapists',
  },
  MemberTracker: {
    to: '/apps/Admin/members/[id]/tracker',
    as: '/admin/members/:id/tracker',
  },
  ProviderCreate: {
    to: '/apps/Admin/create/providers',
    as: '/admin/create/providers',
  },
  ProviderList: {
    to: '/apps/Admin/providers',
    as: '/admin/providers',
  },
  ProviderDetail: {
    to: '/apps/Admin/providers/[id]',
    as: '/admin/providers/:id',
  },
  CustomerDataIntegrations: {
    to: '/apps/Admin/customer_data_integrations',
    as: '/admin/customer_data_integrations',
  },
  PersonifyIntegrations: {
    to: '/apps/Admin/customer_data_integrations?api=true',
    as: '/admin/customer_data_integrations?api=true',
  },
  CreateSFTPIntegration: {
    to: '/apps/Admin/create/sftp_integrations',
    as: '/admin/create/sftp_integrations',
  },
  SFTPIntegrationFormPage: {
    to: '/apps/Admin/sftp_integrations/[id]',
    as: '/admin/sftp_integrations/:id',
  },
  PersonifyIntegrationFormPage: {
    to: '/apps/Admin/personify_integrations/[id]',
    as: '/admin/personify_integrations/:id',
  },
  CreatePersonifyIntegration: {
    to: '/apps/Admin/create/personify_integrations',
    as: '/admin/create/personify_integrations',
  },
  Tickets: {
    to: '/apps/Admin/tickets',
    as: '/admin/tickets',
  },
  AdminTwoFactorAuthentication: {
    to: '/apps/Admin/settings/two_factor_authentication',
    as: '/admin/settings/two_factor_authentication',
  },
  'AdminTwoFactorAuthentication--SMS': {
    to: '/apps/Admin/settings/two_factor_authentication/sms',
    as: '/admin/settings/two_factor_authentication/sms',
  },
  'AdminTwoFactorAuthentication--App': {
    to: '/apps/Admin/settings/two_factor_authentication/app',
    as: '/admin/settings/two_factor_authentication/app',
  },
  SSOClients: {
    to: '/apps/Admin/sso_clients',
    as: '/admin/sso_clients',
  },
  CreateSSOClient: {
    to: '/apps/Admin/create/sso_clients',
    as: '/admin/create/sso_clients',
  },
  SSOClientDetail: {
    to: '/apps/Admin/sso_clients/[id]',
    as: '/admin/sso_clients/:id',
  },
  PaperTrail: {
    to: '/apps/Admin/paper_trail',
    as: '/admin/paper_trail',
  },
  TrialCreate: {
    to: '/apps/Admin/create/trials',
    as: '/admin/create/trials',
  },
  TrialList: {
    to: '/apps/Admin/trials',
    as: '/admin/trials',
  },
  TrialDetails: {
    to: '/apps/Admin/trials/[id]',
    as: '/admin/trials/:id',
  },
  CohortCoveredLives: {
    to: '/apps/Admin/cohorts/:id',
    as: '/admin/cohorts/:id',
  },
  CoveredLifeDetails: {
    to: '/apps/Admin/covered_lives/:id',
    as: '/admin/covered_lives/:id',
    href: '/admin/covered_lives',
  },
  CohortDetails: {
    pathname: '/admin/customers/[customer_id]/cohort/[cohort_id]',
  },
  CoveredLivesMover: {
    pathname: '/admin/customers/[customer_id]/cohort/[cohort_id]/covered_lives_mover',
  },
  CustomerRateCards: {
    to: '/apps/Admin/customer_rate_cards',
    as: '/admin/customer_rate_cards',
  },
};

export default routes;
