import React from 'react';
import { GetUserIdentifiersQueryResult } from '../../Admin/shared/graphql/queries/getUserIdentifiers.generated';

export type AdminUser = NonNullable<GetUserIdentifiersQueryResult['data']>['user'];

export type AuthContextType = {
  user?: { mfa_enabled: boolean; scopes: [] } & AdminUser;
  loading?: boolean;
  onSignInInitializeUser: (user: AdminUser) => void;
  onLogout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  onSignInInitializeUser: () => {},
  onLogout: () => {},
});
